import '../styling/ContactUs.scss';
import { Row, Col } from 'react-bootstrap';
import MapB from './MapB';
import ContactForm from './ContactForm';

export default function ContactUs() {

  return (
    <div className='contact-us-component'>
        <Row>
          <Col className='page-name'>CONTACT US</Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col  xs={12} sm={12} md={6} lg={6} xl={6} md='auto' className='email'>
            <ContactForm />
          </Col>
          <Col className='info'>
            <Row className='justify-content-md-center'>
              <Col md='auto' className='name'>
                Devin Norrick Attorney at Law, PC<br/>
                Gwen Brannon (MPA Paralegal)
              </Col>
              </Row>
            <Row className='justify-content-md-center'>
              <Col md='auto' className='address'>
                1010 W. 8th Street, Suite 5<br/>
                Anderson, IN 46016
              </Col>
            </Row>
            <Row className='numbers'>
              <div className='number-descriptor'>
                TEL
              </div>
              <a className='number' href='tel:7656422080'>
                (765) 642-2080
              </a>
              <div className='number-descriptor'>
                FAX
              </div>
              <div className='number'>
                (765) 643-3081
              </div>
            </Row>
          </Col>
        </Row>
        <Row className='map'>
          <Col>
            <MapB />
          </Col>
        </Row>
    </div>
  );
}