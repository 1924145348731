import './styling/App.scss';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import PracticeAreas from './components/PracticeAreas';
import Divorce from './components/Divorce';
import Children from './components/Children';
import ContactUs  from './components/ContactUs';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import Home from './components/Home';
import CriminalLaw from './components/CriminalLaw';

function App() {

  const [activeLink, setActiveLink] = useState('Home');

  useEffect(() => {
    var currentPath = window.location.pathname;

    switch(currentPath){
      case '':
        setActiveLink('Home')
        break
      case '/':
        setActiveLink('Home')
        break
      case '/home':
        setActiveLink('Home')
        break
      case '/practice-areas':
        setActiveLink('PracticeAreas')
        break
      case '/divorce':
        setActiveLink('Divorce')
        break
      case '/children':
        setActiveLink('Children')
        break
      case '/contact-us':
        setActiveLink('ContactUs')
        break
      case '/criminal-law':
        setActiveLink('CriminalLaw')
        break
      default:
        setActiveLink('Home')
    }
  }, []);

  return (
    <Container fluid>
      <Header activeLink={activeLink} setActiveLink={setActiveLink}/>
      {activeLink === 'Home' && <Home />}
      {activeLink === 'PracticeAreas' && <PracticeAreas />}
      {activeLink === 'Divorce' && <Divorce />}
      {activeLink === 'Children' && <Children />}
      {activeLink === 'CriminalLaw' && <CriminalLaw />}
      {activeLink === 'ContactUs' && <ContactUs />}
      <Footer />
    </Container>
  );
}

export default App;