import '../styling/Home.scss';
import { Row, Col } from 'react-bootstrap'

export default function Home() {
  return (
    <div className='home-component'>
      <Row className='photo-panel'>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} className='brief-info'>
          <div className='title'>
            Legal Specialist, Attorney at Law
          </div>
          <div className='name'>
            Devin Norrick
          </div>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} xl={6} className='photo'>
          <img src='fillerImage.png' alt='Devin Norrick' />
        </Col>
      </Row>
      <Row className='summary-panel'>
        <Col sm={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
          <div className='sum-part-1'>
            If you are in need of legal help, your best option is calling the Norrick Law Office.
          </div>
          <div className='sum-part-2'>
            Retaining an attorney is a major step that takes great consideration. 
            You need an attorney that will listen to your needs and fight hard for you. 
            Mr.Norrick understands the importance of supporting your wishes and using the law to support those wishes.
          </div>
          <div className='sum-part-3'>
            Mr.Norrick and his staff will work with you to determine all of your options and encourage you to take an active role in your case. 
            It is you, the client, who chooses the direction and ultimate goals that you wish to accomplish. 
            Whether it is a divorce, death of a loved one, or trying to spend more time with your child, Mr. Norrick is there to help. 
            He will work with you in order to come to an agreement and obtain your best resolution in court.
          </div>
        </Col>
      </Row>
    </div>
  );
}