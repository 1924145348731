import '../styling/Children.scss';
import { Row, Col } from 'react-bootstrap';

export default function Children() {
    return (
      <div className='children-component'>
          <Row>
            <Col className='page-name'>CHILDREN</Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={12} md={10} lg={8} xl={6} md='auto' className='description'>
              Citations can be filed when either parent is not compliant with a court order or with the Indiana Parenting Time Guidelines.<br/><br/>
              Modifications are filed when there is a change in circumstances, such as a change in income that would modify support. 
              This is allowed one time within a 12-month time period and must change either up or down by at least 20%.<br/><br/>
              Emancipations are filed prior to a child turning 19. You also can file if the child joins the military or becomes married.
            </Col>
            <Col className='container'>
              <Row><Col className='list-heading'>Helpful Links</Col></Row>
              <Row><Col className='list'>
                <ul>
                  <li><a href='https://uptoparents.org/'>Up to Parents</a></li>
                  <li><a href='https://public.courts.in.gov/mycase/#/vw/Search'>My Case</a></li>
                  <li><a href='https://www.in.gov/courts/supreme/'>Indiana Supreme Court - Self Service</a></li>
                  <li><a href='http://mycourts.in.gov/csc/Parents/Default.aspx'>Support calculator</a></li>
                  <li><a href='https://www.in.gov/courts/rules/parenting/'>Indiana Parenting Time Guidelines</a></li>
                  <li><a href='https://www.madisoncounty.in.gov/'>Madison County</a></li>
                </ul>
              </Col></Row>
            </Col>
          </Row>
      </div>
    );
  }