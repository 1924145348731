import '../styling/Header.scss';
import { Nav, Navbar } from 'react-bootstrap';

export default function Header ({ activeLink, setActiveLink }) {

  const handleOnSelect = eventKey => setActiveLink(eventKey);

  return (
    <Navbar collapseOnSelect expand='lg' variant='light' className='navbar header-component'>
      <Navbar.Brand>NORRICK LAW OFFICES</Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='ml-auto links' activeKey={activeLink} onSelect={handleOnSelect}>
          <Nav.Link eventKey='Home'>HOME</Nav.Link>
          <Nav.Link eventKey='PracticeAreas'>PRACTICE AREAS</Nav.Link>
          <Nav.Link eventKey='Divorce'>DIVORCE</Nav.Link>
          <Nav.Link eventKey='Children'>CHILDREN</Nav.Link>
          <Nav.Link eventKey='CriminalLaw'>CRIMINAL LAW</Nav.Link>
          <Nav.Link eventKey='ContactUs'>CONTACT US</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Text>Hablamos Español</Navbar.Text>
    </Navbar>
  );
}