import React from 'react';
import '../styling/Map.scss';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoibmVpZ2h0ZXIiLCJhIjoiY2tuNWN0NmwzMDJ5bzJ3cmczcWd0ZnV1biJ9.v4795x44xQHt-A3-a1nC3Q';

class MapB extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lng: -85.69220641846204,
      lat: 40.10826949617139,
      zoom: 15
    };
    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    const { lng, lat, zoom } = this.state;
    const map = new mapboxgl.Map({
    container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
    new mapboxgl.Marker()
    .setLngLat([-85.69220641846204, 40.10826949617139])
    .addTo(map);
    var nav = new mapboxgl.NavigationControl();
    map.addControl(nav, 'top-left');
  }

  render() {
    const { lng, lat, zoom } = this.state;
    return (
      <div>
        <div ref={this.mapContainer} className="map-container" />
      </div>
    );
  }
}

export default MapB;