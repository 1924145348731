import '../styling/CriminalLaw.scss';
import { Row, Col } from 'react-bootstrap';

export default function CriminalLaw() {
    return (
      <Row className='criminal-law-component'>
        <Col>
          <Row>
            <Col className='page-name'>CRIMINAL LAW</Col>
          </Row>
          <Row>
            <Col className='list'>
              <ul>
                <li className='main-bullet-point'>Felony and Misdemeanor Crimes</li>
                <li className='main-bullet-point'>Specialized Driving Permits</li>
                <li className='main-bullet-point'>Expungements</li>
                <li className='main-bullet-point'>Probation Violations</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }