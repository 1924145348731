import React, { Component } from "react";
import { Form } from 'react-bootstrap';

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      message: '',
      phone: '',
    };   
  } 

  render() {
    return (      
      <>
        <Form.Group controlId='name'>
          <Form.Control placeholder='Name' onChange={e => this.setState({name: e.target.value})}/>
        </Form.Group>
        <Form.Group controlId='phone'>
          <Form.Control placeholder='Phone Number' onChange={e => this.setState({phone: e.target.value})}/>
        </Form.Group>
        <Form.Group controlId='message'>
          <Form.Control as='textarea' placeholder='Question' rows={8} onChange={e => this.setState({message: e.target.value})}/>
        </Form.Group>
        <a className='submit' href={`mailto:contact@norricklawoffices.com?subject=Question for Norrick Law&body=Name: ${this.state.name}%0D%0APhone: ${this.state.phone}%0D%0AMessage: ${this.state.message}%0D%0A`}>Send Email</a>
      </>
    );
}
}

export default ContactForm;