import '../styling/PracticeAreas.scss';
import { Row, Col } from 'react-bootstrap';

export default function PracticeAreas() {
    return (
      <Row className='practice-areas-component'>
        <Col>
          <Row>
            <Col className='page-name'>PRACTICE AREAS</Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className='description'>
                Since opening in 2004, Norrick Law Offices has practiced in the areas of family law, probate, criminal law, specialized driving privileges, and expungements.
                Norrick Law Offices has represented clients in a wide variety of legal needs and is ready to work hard to provide you with the best case.
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} className='list'>
              <ul>
                <li className='main-bullet-point'>Family Law</li>
                  <ul>
                    <li>Divorce</li>
                    <li>Child support</li>
                    <li>Modifications of support and visitation</li>
                    <li>Custody and visitation</li>
                    <li>Paternity</li>
                    <li>Step parent and foster care adoption</li>
                    <li>Post-secondary education expenses</li>
                    <li>19 year old emancipations</li>
                    <li>Grandparent visitation</li>
                    <li>Guardianship</li>
                    <li>Power of Attorney</li>
                    <li>Protective orders</li>
                    <li>Mediation</li>
                  </ul>
                <li className='main-bullet-point'>Probate</li>
                  <ul>
                    <li>Wills</li>
                    <li>Estates</li>
                  </ul>
                <li className='main-bullet-point'>Criminal Law</li>
                <li className='main-bullet-point'>Specialized Driving Privileges</li>
                <li className='main-bullet-point'>Expungements</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }