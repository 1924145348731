import '../styling/Footer.scss';
import cash from '../images/cash.jpg'
import check from '../images/check.jpg'
import discover from '../images/discover.jpg'
import mastercard from '../images/mastercard.jpg'
import visa from '../images/visa.jpg'

import { Row, Col } from 'react-bootstrap';

export default function Footer () {
  return (
    <Row className='footer-component'>
      <Col>
        <Row>
          <Col>
            <p>1010 W. 8th Street, Suite 5. Anderson, IN 46016.</p>
          </Col>
        </Row>
        <Row className='numbers'>
          <div className='number-descriptor'>
            TEL
          </div>
          <a className='number' href='tel:7656422080'>
            (765) 642-2080
          </a>
          <div className='number-descriptor'>
            FAX
          </div>
          <div className='number'>
            (765) 643-3081
          </div>
        </Row>
        <Row className='accepted-payments'>
          <Col>
            <div className='icon-container'>
              <img src={cash} alt='We accept cash'/>
              <img src={check} alt='We accept personal checks'/>
              <img src={discover} alt='We accept discover'/>
              <img src={mastercard} alt='We accept mastercard'/>
              <img src={visa} alt='We accept visa'/>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}