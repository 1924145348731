import '../styling/Divorce.scss';
import { Row, Col } from 'react-bootstrap';

export default function Divorce() {
    return (
      <div className='divorce-component'>
          <Row>
            <Col className='page-name'>DIVORCE</Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={12} md={8} lg={8} xl={6} md='auto' className='description'>
              Mr. Norrick has a genuine concern for families and believes he has a legal and moral obligation to help bring resolution during these difficult times. 
              Experience matters and Devin has lots of experience in bringing resolution to these situations. 
              His genuine belief is that it takes a village to raise a child and if two parents can learn to work together for the betterment of their child, the more successful that child will be.
            </Col>
          </Row>
          <Row className='divorce-cards justify-content-md-center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} md='auto' lg='auto' className='standard-divorce'>
              <Row><Col className='heading'>STANDARD DIVORCE</Col></Row>
              <div className='underline'/>
              <Row><Col>Minimum time to finalize divorce – 60 days from date of filing (per state statute)</Col></Row>
              <Row>
                <Col>
                  <ul>
                    <li>Filing Fee: $181.72</li>
                    <ul>
                      <li>Provisional Hearing/Agreement</li>
                      <li>Final Hearing/Agreement</li>
                    </ul>
                    <li>Assets: some items that are included in the divorce</li>
                    <ul>
                      <li>Marital residence</li>
                      <li>Bank accounts</li>
                      <li>Pensions</li>
                      <li>Retirement Accounts</li>
                      <li>Furniture</li>
                      <li>Vehicles or Motorized equipment such as motorcycles, boats or trailers.</li>
                    </ul>
                  </ul>
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={6} md='auto' lg='auto' className='divorce-with-children'>
              <Row><Col className='heading'>DIVORCE WITH CHILDREN</Col></Row>
              <div className='underline'/>
              <Row>
                <Col>
                  <ul>
                    <li>Additional costs: Child in the Middle Classes (Ages 6 – 17)</li>
                    <li>CIM: $25.00 + $10.00 per child (max cost for children is $20.00)</li>
                    <li>
                      Child Support is determined by the State's calculator using each parent’s gross income. 
                      Other factors that affect child support are health insurance costs, daycare, or other children each party may have.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='custody-cards justify-content-md-center'>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} md='auto' lg='auto'>
              <Row><Col className='heading'>CUSTODY</Col></Row>
              <div className='underline'/>
              <Row>
                <Col>
                  <ul>
                    <li>Physical Custody</li>
                    <ul>
                      <li>Physical custody to mother with parenting time to father</li>
                      <li>Physical custody to father with parenting time to mother</li>
                      <li>Joint physical custody – both parents share child equally</li>
                    </ul>
                    <li>Joint Legal Custody</li>
                    <ul>
                      <li>Both parents have equal rights to education choices, religious affiliations, & decision-making abilities.</li>
                      <li>
                        <a href='https://www.in.gov/courts/rules/parenting/'>Indiana Supreme Court</a> (parenting time guidelines – child support calculator)
                      </li>
                    </ul>
                  </ul>
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={6} md='auto' lg='auto' className='custody-notes'>
              Many times, a parent will come to our office about a divorce and tell us they want sole custody of their children. 
              While there are times when sole custody is in the best interest of children, these times are rare. 
              Most times, both parents are likely to have a joint physical custody. 
              Parenting time is dictated by the Indiana Parenting Time Guidelines.
            </Col>
          </Row>
      </div>
    );
  }